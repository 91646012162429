/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export function line(params) {
    return API.GET('user/line', params)
}

export function kill(params) {
    return API.GET('user/kill', params)
}
export function logs(params) {
    return API.GET('logs/list', params)
}
