<template>
    <div>
        <div class="handle-box">
            <span>
               <el-button type="success" icon="el-icon-refresh" @click="init()">刷新</el-button>
            </span>
        </div>
        <el-table :data="lineUser" style="width: 100%">
            <el-table-column
                prop="id" sortable
                label="标记"
                width="80">
            </el-table-column>
            <el-table-column
                prop="name"
                label="姓名" width="100" sortable
            >
            </el-table-column>
            <el-table-column
                prop="sid" sortable
                label="身份标识" width="100" :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                prop="ostype" sortable
                label="登录设备" width="100"
            >
                <template #default="scope">
                    {{ OSTTYPES[scope.row.ostype || 0] }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ostype" sortable
                label="登录方式" width="100"
            >
                <template #default="scope">
                    {{ LT[scope.row.logintype || 0] }}
                </template>
            </el-table-column>
            <el-table-column prop="ip" sortable label="IP/地址"></el-table-column>
            <el-table-column prop="logintime" label="登录时间" sortable></el-table-column>
            <el-table-column prop="updattime" label="操作时间" sortable></el-table-column>
            <el-table-column prop="staatus" label="会话">
                <template #default="scope">
                    <i style="color:#0aae72;"><i class="iconfont iconquanxian arktitle"></i>监控中</i>
                </template>
            </el-table-column>
            <el-table-column prop="staatus" sortable label="安全等级" width="160">
                <template #default="scope" >
                    <i class="el-icon-loading" :style="{color:cools[scope.row.logintype]}"></i>
                    <i class="el-icon-lock " :style="{color:cools[scope.row.logintype]}"
                       v-for="count in (scope.row.logintype+1)"></i>
                </template>
            </el-table-column>
            <el-table-column prop="staatus" sortable label="风险" >
                <template #default="scope">
                 <span :style="{color:cools[scope.row.logintype]}">{{ST[scope.row.logintype]}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="250" te>
                <template #default="scope">
                    <el-button size="mini" type="warning" plain @click="kill(scope.row)">踢出</el-button>
                    <el-button size="mini" type="danger" plain @click="kill(scope.row,0)">冻结</el-button>
                    <el-button size="mini" plain @click="showlog(scope.row)">日志</el-button>
                </template>
            </el-table-column>

        </el-table>


        <el-dialog title="操作日志"  width="1000px" v-model="logdig">
            <div class="table">
                <el-table :data="page.list" v-loading="loading" element-loading-text="拼命加载中" border height="350">
                    <el-table-column prop="uid" label="用户id"></el-table-column>
                    <el-table-column prop="username" label="用户名" width="100"></el-table-column>
                    <el-table-column prop="ip" label="ip"></el-table-column>
                    <el-table-column prop="userAgent" label="浏览器" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="operation" label="描述" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="params" label="参数" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="time" label="耗时(ms)" width="80"></el-table-column>
                    <el-table-column prop="addtime" label="操作时间" width="150"></el-table-column>
                    <el-table-column prop="status" label="结果" width="80">
                        <template #default="scope">
                            <i :class="STATUS[scope.row.status].icon"
                               :style="{color:STATUS[scope.row.status].color}">{{
                                    STATUS[scope.row.status].name
                                }}</i>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- ======分页====== -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="page.pageNum" :page-size="page.pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {line,kill,logs} from "../../api/sys/api_tool"

export default {
    name: "onlineusers",
    data() {
        return {
            lineUser: [],
            OSTTYPES: ["未知", "PC", "APP", "H5/小程序"],
            LT: ["账号登录", "短信登录", "扫码登录", "小程序登录", "自动登录",],
            ST: ["一般", "中", "低","低","低"],
            cools: ['#F56C6C', '#E6A23C', '#F56C6C', '#409EFF', '#67C23A', '#67C23A', '#67C23A', '#67C23A'],
            STATUS: {
                '403': {name: "越权", icon: 'el-icon-circle-close', color: '#E6A23C'},
                '500': {name: "失败", icon: 'el-icon-circle-close', color: '#DC143C'},
                '200': {name: "成功", icon: 'el-icon-circle-check', color: '#30d351'},
            },
            logdig:false,
            row: undefined,
            page: {
                list: [],
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
        }
    }
    ,
    methods: {
        init() {
          line().then((res) => {
                this.lineUser = res.data;
            });
        },
        kill(user, status, all) {
            this.$confirm("确认[" + (status ? '冻结-' : '踢出- ') + user.name + "]账号吗?", "警告", {confirmButtonClass: "el-button--warning"}).then(() => {
              kill({uid: user.id, sid: user.sid, status: status}).then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                });
            })
        },
        showlog(row) {
            this.logdig = true;
            this.row = row;
            this.init_logs();
        },
        handleSizeChange(val) {
            this.page.pageSize = val;
            this.init_logs();
        },
        handleCurrentChange(val) {
            this.page.pageNum = val;
            this.init_logs();
        },
        init_logs() {
          logs({
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
                uid: this.row.id
            }).then((res) => {
                if (res.code === 200) {
                    this.page.list = res.pageData.list;
                    this.page.total = res.pageData.total;
                }
            });
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>


</style>
